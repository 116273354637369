<template>
<div class="detail-dialog">
  <div class="detail-box">
    <ul class="box-table">
      <li class="box-table-item">
        <span class="item-left">申请人：</span>
        <span class="item-right">{{detailData.realName}}</span>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 1">
        <span class="item-left">请假类型：</span>
        <div class="item-right">
          <span v-if="detailData.leaveType === 1">事假</span>
          <span v-if="detailData.leaveType === 2">年假</span>
          <span v-if="detailData.leaveType === 3">病假</span>
          <span v-if="detailData.leaveType === 4">其他</span>
        </div>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 3">
        <span class="item-left">请假类型：</span>
        <div class="item-right">
          外出
        </div>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 2">
        <span class="item-left">出差事由：</span>
        <span class="item-right">{{detailData.cause}}</span>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 2">
        <span class="item-left">交通工具：</span>
        <div class="item-right">
          <span v-if="detailData.vehicle === 1">飞机</span>
          <span v-if="detailData.vehicle === 2">火车</span>
          <span v-if="detailData.vehicle === 3">汽车</span>
          <span v-if="detailData.vehicle === 4">其他</span>
        </div>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 2">
        <span class="item-left">出发城市：</span>
        <div class="item-right">
          {{ detailData.businessTravelList[0].departureProvince + '' + detailData.businessTravelList[0].departureCity + '' + (detailData.businessTravelList[0].departureDistrict ? detailData.businessTravelList[0].departureDistrict : '')}}
        </div>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 2">
        <span class="item-left">目的地：</span>
        <div class="item-right">
          {{ detailData.businessTravelList[0].destinationProvince + '' + detailData.businessTravelList[0].destinationCity + '' + (detailData.businessTravelList[0].destinationDistrict ? detailData.businessTravelList[0].destinationDistrict : '') }}
        </div>
      </li>
      <li class="box-table-item">
        <span class="item-left">开始时间：</span>
        <span class="item-right">{{detailData.startDate}}</span>
      </li>
      <li class="box-table-item">
        <span class="item-left">结束时间：</span>
        <span class="item-right">{{detailData.endDate}}</span>
      </li>
      <li class="box-table-item">
        <span class="item-left">时长：</span>
        <span class="item-right">{{detailData.duration}}天</span>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 1">
        <span class="item-left">请假事由：</span>
        <span class="item-right">{{detailData.cause}}</span>
      </li>
      <li class="box-table-item" v-if="detailData.approvalType === 3">
        <span class="item-left">外出事由：</span>
        <span class="item-right">{{detailData.cause}}</span>
      </li>
      <li class="box-table-item">
        <span class="item-left">提交申请时间：</span>
        <span class="item-right">{{detailData.createdDate}}</span>
      </li>
      <li class="box-table-item" v-if="detailApproval === 2">
        <span class="item-left">审批意见：</span>
        <div class="item-right item-bottom">
          <el-radio-group v-model="detailList.opinion">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">驳回</el-radio>
          </el-radio-group>
          <el-input v-if="detailList.opinion === 3"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="在此输入驳回原因"
            v-model="detailList.textarea">
          </el-input>
        </div>
      </li>
      <li class="box-table-item item-withdraw" v-if="detailApproval === 1 && detailData.approvalStatus === 4">
        <span class="item-left">撤回申请</span>
        <div class="item-right">
          <div class="item-right-date">{{detailData.revocationDate}}</div>
          <div class="item-right-remark">撤回原因：{{detailData.refuseReason ? detailData.refuseReason : '暂无'}}</div>
        </div>
      </li>
    </ul>
    <div class="box-step">
      <div class="step-top">审批节点</div>
      <ul class="step-content" v-if="detailData.approvalProcessList">
        <li class="step-item" v-for="item in detailData.approvalProcessList" :key="item.id" :class="{'is_finish': item.approvalStatus > 1, 'is_process': item.approvalStatus === 1, 'bottom-hei': item.approvalStatus === 3}">
          <span class="step-cir"></span>
          <span class="step-line"></span>
          <div class="step-item-content">
            <h3 class="step-item-tit">
              {{item.approver}}
              （<span v-if="item.approvalStatus === 0">未开始</span>
              <span v-if="item.approvalStatus === 1">审批中</span>
              <span v-if="item.approvalStatus === 2">已通过</span>
              <span v-if="item.approvalStatus === 3">已驳回</span>
              <span v-if="item.approvalStatus === 4">已撤回</span>）
            </h3>
            <div class="step-item-date">{{item.approvalDate}}</div>
            <div class="step-item-desc" v-if="item.approvalStatus === 3">
              <span class="desc-triangle"></span>
              <span v-if="detailData.refuseReason && detailData.refuseReason.length < 30">
                {{detailData.refuseReason}}
              </span>
              <el-popover
                v-if="detailData.refuseReason && detailData.refuseReason.length > 30"
                placement="bottom"
                title=""
                width="500"
                trigger="hover"
                :content="detailData.refuseReason">
                <span slot="reference" class="desc-ellipsis">{{detailData.refuseReason}}</span>
              </el-popover>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div slot="footer" class="dialog-footer" align="center">
    <el-button v-if="detailApproval === 2" type="primary" @click="approvalSave">提交审批</el-button>
    <el-button v-if="detailApproval === 1" @click="approvalClose">取消</el-button>
  </div>
</div>  
</template>

<script>
export default {
  props: {
    detailApproval: {
      type: Number,
      default: 1
    },
    detailData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      // detailData: {}, // 弹出框数据
      detailList: {
        opinion: '', // 审批意见 审批状态（2:已通过 3:已驳回 4:已撤回）
        textarea: '' // 驳回原因 
      }
    };
  },
  methods: {
    /** 提交审批 */
    approvalSave() {
      if (!this.detailList.opinion) {
        return this.$message('请选择审批意见!');
      }
      if (this.detailList.opinion === 3 && !this.detailList.textarea) {
        return this.$message('请填写驳回原因!');
      }
      this.$emit('approvalSave', {
        approvalId: this.detailData.approvalProcessList[0].approvalId,
        opinion: this.detailList.opinion,
        textarea: this.detailList.textarea
      });
    },
    /** 关闭 */
    approvalClose() {
      this.$emit('approvalClose');
    }
  }
};
</script>

<style lang="less" scoped>
.detail-dialog{
  text-align: left;
  .detail-box{
    display: flex;
    .box-table{
      // flex: 1;
      width: 570px;
      border: 1px solid #D1D5D8;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 130px;
        width: 1px;
        height: 100%;
        background-color: #D1D5D8;
      }
      .box-table-item{
        min-height: 40px;
        line-height: 24px;
        border-bottom: 1px solid #D1D5D8;
        &:last-child{
          border: none;
        }
        display: flex;
        .item-left{
          width: 120px;
          // min-width: 100px;
          color: #999999;
          text-align: right;
          padding: 8px 10px;
          // box-sizing: border-box;
        }
        .item-right{
          flex: 1;
          color: #4B5162;
          padding: 8px 15px;
          &.item-bottom{
            padding-bottom: 15px;
            .el-textarea{
              margin-top: 6px;
            }
          }
        }
        &.item-withdraw{
          min-height: 64px;
          .item-left{
            color: #F23D2F;
          }
          .item-right{
            .item-right-date{
              color: #F23D2F;
            }
            .item-right-remark{
              color: #4B5162;
            }
          }
        }
      }
    }
    .box-step{
      // width: 260px;
      flex: 1;
      min-width: 260px;
      margin-left: 10px;
      border: 1px solid #D1D5D8;
      padding: 18px 15px;
      .step-top{
        color: #4B5162;
        margin-bottom: 30px;
      }
      .step-content{
        .step-item{
          height: 90px;
          position: relative;
          .step-cir{
            height: 6px;
            width: 6px;
            background-color: #D0D5E2;
            border: 4px solid #FFFFFF;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }
          .step-line{
            width: 2px;
            height: 100%;
            background-color: #D0D5E2;
            position: absolute;
            top: 0;
            left: 6px;
          }
          // 结束状态
          &.is_finish{
            .step-cir{
              background-color: #3488FE;
            }
            .step-line{
              background-color: #3488FE;
            }
          }
          // 当前状态
          &.is_process{
            .step-cir{
              background-color: #3488FE;
            }
          }
          &:last-child{
            height: 50px;
            &.bottom-hei{
              height: 120px;
            }
            .step-line{
              height: 30px;
            }
          }
          &.bottom-hei{
            height: 120px;
          }
          .step-item-content{
            padding-left: 30px;
            position: relative;
            top: -8px;
            .step-item-tit{
              font-size: 15px;
              font-weight: 400;
              color: #4B5162;
              line-height: 24px;
              margin: 0;
            }
            .step-item-date{
              font-size: 12px;
              color: #999999;
              line-height: 20px;
            }
            .step-item-desc{
              font-size:13px;
              line-height: 19px;
              color:#F13D2E;
              background:rgba(241,61,46,0.1);
              border-radius:6px;
              padding: 5px 10px;
              position: relative;
              margin-top: 8px;
              .desc-triangle{
                position: absolute;
                height: 0px;
                width: 0;
                top: -8px;
                left: 10px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid rgba(241, 61, 46, 0.1);
              }
              .desc-ellipsis{
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }
  .dialog-footer{
    margin-top: 25px;
  }
}
</style>