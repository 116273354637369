<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        >
          <i v-if="nav.name === '审批管理'" class="icon icon-approval"></i>
          <i v-if="nav.name === '我的申请'" class="icon icon-application"></i>
          <i v-if="nav.name === '统计报表'" class="icon icon-excel"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
      

    <div class="approval-manage">
      <el-form :inline="true" :model="filterForm" class="filter-form">
        <el-form-item label="申请类型">
          <el-select clearable class="form-row" v-model="filterForm.approvalType" placeholder="请选择申请类型">
            <el-option label="请假" value="1"></el-option>
            <el-option label="出差" value="2"></el-option>
            <el-option label="外出" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时长(天)">
          <el-input class="form-row" v-model="filterForm.duration" placeholder="请输入时长"></el-input>
        </el-form-item>
        <el-form-item label="申请人">
          <el-input class="form-row" v-model="filterForm.applicant" placeholder="请输入申请人搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="realName" label="申请人"></el-table-column>
        <el-table-column align="center" prop="approvalType" label="申请类型">
          <template slot-scope="scope">
            <div>{{ scope.row.approvalType === 1 ? '请假' : (scope.row.approvalType === 2 ? '出差' : '外出') }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="duration" label="时长">
          <template slot-scope="scope">
            <div>{{ scope.row.duration }}天</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="approvalStatus" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.approvalStatus === 1" style="color: #FD8F36">审批中</span>
            <span v-if="scope.row.approvalStatus === 2" style="color: #41B873">已通过</span>
            <span v-if="scope.row.approvalStatus === 3" style="color: #F23D2F">未通过</span>
            <span v-if="scope.row.approvalStatus === 4" style="color: #666666">已撤回</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createdDate" label="提交申请时间"></el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="detailsRow(scope.row, 1)" type="text" size="small">详情</el-button>
            <el-button v-if="scope.row.approvalStatus === 1 && scope.row.approver" @click="detailsRow(scope.row, 2)" type="text" size="small" >审批</el-button>
            <el-button v-else :disabled="true" type="text" size="small" >审批</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="pageNum"
          @current-change="paginationChange"
        ></el-pagination>
      </div>
    </div>

  </div>
        </div>
    <!-- 审批 - 弹出框 -->
    <el-dialog :title="detailTitle" :visible.sync="detailVisible" width="65%" top="3%" align="center">
      <dutyDetail v-if="detailVisible" :detailApproval="detailApproval" :detailData="detailData" @approvalSave="approvalSave" @approvalClose="approvalClose" />
    </el-dialog>
  </div>
</template>
<script>
import dutyDetail from './components/dutyDetail.vue';

import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";

export default {
  components: {
    dutyDetail,
    nfBreadcrumb
  },
  data() {
    return {

      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "勤务管理"
        }
      ],
      activeNav: 0,
      tabsNav: [
        {
          name: "审批管理",
          url: "approvalManagement"
        },
        {
          name: "我的申请",
          url: "myApplication"
        }
        // ,
        // {
        //   name: "统计报表",
        //   url: "dutyStatistics"
        // }
      ],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      // 筛选表单 - 值
      filterForm: {
        approvalType: '', // 审批类型（1:请假 2:出差 3:外出）
        duration: '', // 时长
        applicant: '' // 申请人
      },
      // 列表数据
      tableData: [],
      detailVisible: false, // 审批弹出框状态
      detailTitle: '', // 弹出框标题
      detailData: {}, // 弹出框数据
      detailApproval: 1 // 1、详情、2、审批
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    // 查询
    onSubmit() {
      this.getListData();
      this.pageNum = 1;
    },
    // 获取列表数据
    async getListData(page) {
      const params = {
        pageNum: page || 1,
        pageSize: this.pageSize,
        approvalType: this.filterForm.approvalType,
        duration: this.filterForm.duration,
        applicant: this.filterForm.applicant
      };
      const data = await this.$fetchData.fetchGet(params, '/api/attendance/v1/approval/find-approval-page');
      if (data.code === '200') {
        if (data.result && data.result.rows) {
          this.tableData = data.result.rows;
          this.total = data.result.total;
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    // 详情 / 审批
    async detailsRow(data, type) {
      this.detailApproval = type;
      if (type === 1) {
        this.detailTitle = data.approvalType === 1 ? '请假详情' : (data.approvalType === 2 ? '出差详情' : '外出详情');
      } else {
        this.detailTitle = data.approvalType === 1 ? '请假审批' : (data.approvalType === 2 ? '出差审批' : '外出审批');
      }
      const res = await this.$fetchData.fetchGet({}, '/api/attendance/v1/approval/' + data.id);
      if (res.code === '200') {
        this.detailVisible = true;
        this.detailData = res.result;
      } else {
        this.$message({ type: 'error', message: res.message });
      }
    },
    // 提交审批
    async approvalSave(data) {
      const params = {
        approvalId: data.approvalId,
        approvalStatus: data.opinion,
        refuseReason: data.textarea
      };
      const res = await this.$fetchData.fetchPost(params, '/api/attendance/backend/approval/audit', 'json');
      if (res.code === '200') {
        this.$message({ type: 'success', message: '已成功提交审批' });
        this.getListData(this.pageNum);
        this.detailVisible = false;
      } else {
        this.$message({ type: 'error', message: res.message });
      }
    },
    // 取消审批
    approvalClose() {
      this.detailVisible = false;
    },
    // 改变页码
    paginationChange(page) {
      this.getListData(page);
      this.pageNum = page;
    }
  }
};
</script>
<style lang="less" scoped>
.approval-manage{
  padding: 20px 10px;
  .el-form-item {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }
  .table-pagination{
    text-align: center;
    margin: 40px 0;
  }
}
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-approval {
      background-image: url("../../assets/images/dutyManagement/icon_approval.png");
    }

    &-application {
      background-image: url("../../assets/images/dutyManagement/icon_application.png");
    }

    &-excel{
      background-image: url("../../assets/images/dutyManagement/icon_excel.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
